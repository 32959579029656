<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="w-100 landing-page"
		:style="colorsStyles">
		<hero
			:tagline="heroData.tagline"
			:title="heroData.title"
			:description="heroData.description"
			:product="heroData.searchName"
			:logo="heroData.logo"
			:highlight-color="highlightColors.primary"
			:variant-images="heroData.variantImages"
			:background-style="heroData.backgroundStyle"
			:carousel-interval="heroData.interval" />
		<div
			v-if="Object.keys(summaryData).length > 0">
			<summary-section
				id="summary-section"
				:image="summaryData.image"
				:header-content="summaryData.headerContent"
				:description="summaryData.description"
				:brochure-url="summaryData.brochureUrl" />
		</div>
		<icons-section
			style="height: max-content !important;"
			:icons="iconsData.icons"
			:max-cols="iconsData.cols"
			:background-style="iconsData.background"
			include-addendum
			:text-style="iconsData.textStyle"
			smaller-texts />
		<stages
			v-if="Object.keys(stagesData).length > 0"
			:intro="stagesData.intro"
			:stages="stagesData.stages" />
		<div
			v-if="Object.keys(accordionData).length > 0">
			<accordion
				:items="accordionData" />
		</div>
		<div
			v-if="Object.keys(benefitsData).length > 0"
			style="height: max-content !important;">
			<benefits
				:highlight-color="highlightColors.primary"
				:sections="benefitsData.sections" />
		</div>
		<div
			v-if="['xs', 'sm', 'md'].includes(windowWidth)"
			class="banner-container pt-5">
			<div class="banner-left-line" />
			<div class="banner">
				<p v-html="translate('usa_based_company')" />
			</div>
			<div class="banner-right-line" />
		</div>
		<page-section style="height: max-content !important;">
			<div id="fda_msg">
				<div
					class="mx-auto text-center">
					<div
						class="p-4 mx-auto text-medium"
						style="background-color: #f5f5f5">
						{{ disclaimer.text }}
					</div>
					<div
						class="mt-3 p-4 mx-auto text-medium"
						style="border: 2px solid rgb(0 0 0 / 0.5)"
						v-html="disclaimer.text2" />
				</div>
			</div>
		</page-section>
		<div
			style="height: max-content !important;">
			<reviews
				:highlight-color="highlightColors.primary"
				:reviews="reviewsData.reviews"
				:video-url="reviewsData.videoUrl"
				:text-color="reviewsData.textColor" />
		</div>
		<div id="buy-section">
			<buy-section
				:highlight-color="highlightColors.primary"
				:background="buySectionData.background"
				background-height="41vh"
				:title-classes="{ 'pt-3 pb-4 text-uppercase': true }"
				:product="buySectionData.searchName" />
		</div>
		<div
			v-show="!['xs', 'sm', 'md'].includes(windowWidth)"
			class="floating-image">
			<div class="center-div">
				<div style="width: 200px; height: 126px">
					<img
						style="width: 200px; height: 126px"
						alt="USA Based Company"
						:src="usaBasedCompanyImage">
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Accordion from '../ProductLandingPage3/Accordion';
import Benefits from './Benefits';
import BuySection from '../../../LandingPages2/components/BuySection';
import Hero from '../Hero';
import IconsSection from '../IconsSection';
import PageSection from '../PageSection.vue';
import Stages from '../Stages';
import Reviews from '../Reviews';
import SummarySection from '../ProductLandingPage2/Summary';
import AgencySite from '@/mixins/AgencySite';
import WindowSizes from '@/mixins/WindowSizes';
import { S3_PATH } from '@/settings/Images';
import { PRODUCT_PAGES } from '@/settings/Agency/LandingPages';
import { RESTRICTED_BY_COUNTRY } from '@/settings/LandingPages2';
import { META_DESCRIPTION } from '@/settings/Meta';
import {
	AgencyTaglines,
	LandingPages,
	Products,
	Testimonials,
} from '@/translations';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
import ReplicatedSite from '@/util/ReplicatedSite';

export default {
	name: 'AgencyProductLandingPage',
	metaInfo() {
		const content = META_DESCRIPTION[`landing_page_${this.pageName.toLowerCase()}`];
		if (content !== undefined) {
			return {
				meta: [
					{ vmid: 'description', name: 'description', content },
				],
			};
		}
		return {};
	},
	messages: [
		AgencyTaglines,
		LandingPages,
		Products,
		Testimonials,
	],
	components: {
		Accordion,
		Benefits,
		BuySection,
		Hero,
		IconsSection,
		PageSection,
		Reviews,
		Stages,
		SummarySection,
	},
	mixins: [AgencySite, WindowSizes],
	props: {
		pageName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
		};
	},
	computed: {
		usaBasedCompanyImage() {
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/agency/usa_based_company.png`;
		},
		heroData() {
			const {
				description,
				searchName,
				title,
				logo,
				variantImages,
				backgroundImage,
				interval,
				tagline,
			} = PRODUCT_PAGES[this.pageName].hero;

			const backgroundStyles = {
				'background-image': `url(${this.requireAgencyAsset(backgroundImage)})`,
				'background-size': 'cover',
				'background-color': '#ffffff',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
			};

			const backgroundStyleStr = Object.keys(backgroundStyles)
				.map((key) => `${key}:${backgroundStyles[key]};`)
				.join('');

			return {
				description: this.translate(description),
				searchName,
				title: title ? this.translate(title) : '',
				variantImages: variantImages.map((variant) => {
					const result = { ...variant };
					if (result.image) {
						result.image = this.requireAgencyAsset(result.image);
					}
					if (result.text) {
						result.text = this.translate(result.text);
					}
					return result;
				}),
				interval,
				backgroundStyle: backgroundStyleStr,
				logo: this.requireAgencyAsset(logo),
				tagline: tagline ? this.translate(tagline) : '',
			};
		},
		colorsStyles() {
			return {
				'--product-primary-color': this.highlightColors.primary,
				'--product-secondary-color': this.highlightColors.secondary,
				'--product-tertiary-color': this.highlightColors.tertiary,
			};
		},
		contentStyles() {
			return {
				'.zlem-color': {
					style: { color: '#65B0E6' },
				},
				'.bran-color': {
					style: { color: '#DF703D' },
				},
				'.byom-color': {
					style: { color: '#FCBE5E' },
				},
				'b:not(.zlem-color):not(.bran-color):not(.byom-color), ul, h5, h6': {
					style: { color: `${this.highlightColors.primary}` },
				},
				ul: {
					style: { paddingLeft: '1.5rem' },
				},
				'li>span': {
					class: 'font-weight-bold',
				},
				'p, li>span': {
					style: { color: '#73818f' },
				},
				b: {
					class: 'font-weight-bolder',
				},
				'h5, h6': {
					class: 'font-weight-bolder p-0 m-0',
				},
			};
		},
		highlightColors() {
			return PRODUCT_PAGES[this.pageName].colors;
		},
		summaryData() {
			const summary = PRODUCT_PAGES[this.pageName].summary ?? {};

			if (Object.keys(summary).length === 0) {
				return {};
			}

			const {
				image,
				title,
				description,
			} = summary;

			return {
				image: this.requireAsset(image),
				headerContent: this.translate(title),
				description: this.translate(description),
			};
		},
		stagesData() {
			const stages = PRODUCT_PAGES[this.pageName].stages ?? {};

			if (Object.keys(stages).length === 0) {
				return {};
			}

			const result = stages.stages.map((stage) => {
				const {
					image,
					backgroundImage,
					title,
					description,
				} = stage;
				return {
					image: this.requireAgencyAsset(image),
					backgroundImage: this.requireAgencyAsset(backgroundImage),
					title: this.translate(title),
					description: this.translate(description),
				};
			});

			return {
				intro: this.translate(stages.intro),
				stages: result,
			};
		},
		iconsData() {
			const {
				icons,
				iconCols,
				background,
				textStyle,
			} = PRODUCT_PAGES[this.pageName].overview;

			const result = icons.map((icon) => ({
				text: this.translate(icon.text),
				file: this.requireAsset(icon.file),
			}));

			return {
				cols: iconCols,
				icons: result,
				background,
				textStyle: textStyle || '',
			};
		},
		accordionData() {
			const accordion = PRODUCT_PAGES[this.pageName].accordion ?? {};

			if (Object.keys(accordion).length === 0) {
				return {};
			}

			return accordion.map((faq) => ({
				title: this.translate(faq.title),
				content: this.translate(faq.content, faq.image ? { image: this.requireAsset(faq.image) } : {}),
			}));
		},
		benefitsData() {
			const benefits = PRODUCT_PAGES[this.pageName].benefits ?? {};

			if (Object.keys(benefits).length === 0) {
				return {};
			}

			const {
				sections,
			} = benefits;

			const result = sections.map((section) => {
				const {
					image,
					title,
					content,
					cta,
					reversed,
				} = section;
				const sectionData = {
					image: this.requireAsset(image),
					title: this.translate(title),
					content: applyAttrsToHtmlString(this.translate(content), this.contentStyles),
					reversed: reversed || false,
				};

				if (cta) {
					sectionData.cta = this.transformCta(cta);
				}

				return sectionData;
			});

			return {
				sections: result,
			};
		},
		reviewsData() {
			const { reviews, videoUrl, textColor } = PRODUCT_PAGES[this.pageName].reviews;

			const result = reviews.map((review) => {
				const {
					image,
					author,
					content,
					stars,
				} = review;

				return {
					image: this.requireAsset(image),
					author,
					content: applyAttrsToHtmlString(`<b>"</b>${this.translate(content)}<b>"</b>`, this.contentStyles),
					stars,
				};
			});

			return {
				reviews: result,
				videoUrl,
				textColor,
			};
		},
		buySectionData() {
			const {
				background,
				searchName,
			} = PRODUCT_PAGES[this.pageName].buy;

			return {
				background: background.startsWith('#') ? background : this.requireAsset(background),
				searchName,
			};
		},
		disclaimer() {
			const {
				text,
				text2,
			} = PRODUCT_PAGES[this.pageName].disclaimer;
			return {
				text: this.translate(text),
				text2: this.translate(text2),
			};
		},
	},
	created() {
		if (typeof PRODUCT_PAGES[this.pageName] === 'undefined') {
			this.$router.replace({ name: 'PageNotFound', query: { redirectTo: this.getRedirectName('Store') } });
			return;
		}
		const restricted = RESTRICTED_BY_COUNTRY[this.country] ?? RESTRICTED_BY_COUNTRY.default ?? [];
		if (restricted.includes(this.pageName)) {
			this.$router.replace({ name: this.getRedirectName('Store') });
		}

		this.replicated.clear();

		const replicatedUsername = this.$replicated.replicatedId();
		if (replicatedUsername) {
			this.replicated.getReplicatedSiteNav(replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}
	},
	methods: {
		requireAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// return require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${this.pageName}/${directory}`;
		},
		requireAgencyAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// return require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/agency/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/agency/${this.pageName}/${directory}`;
		},
		transformCta(cta) {
			const result = {
				text: this.translate(cta.text),
			};

			if (cta.url) {
				result.url = cta.url;
			}

			if (cta.target) {
				result.target = cta.target;
			}
			return result;
		},
	},
};
</script>
<style scoped lang="scss">
html, body {
	scroll-behavior: smooth;
}
.landing-page::v-deep {
	.product, {
		&-primary-color {
			color: var(--product-primary-color) !important;
		}
		&-secondary-color {
			color: var(--product-secondary-color) !important;
		}
		&-tertiary-color {
			color: var(--product-tertiary-color) !important;
		}
	}
	.section-header {
		font-size: 2rem;
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
	.hero-title .primary-header {
		font-size: 4.5rem;
		letter-spacing: 0.5rem;
		font-family: Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
	.hero-title .secondary-header {
		font-size: 2rem;
		letter-spacing: 0.3rem;
		font-family: Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
	.font-weight-bolder {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
	.product-tagline {
		font-family: Montserrat, sans-serif !important;
		font-weight: 800 !important;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 25px;
	}
}
.floating-image {
	position: fixed;
	top: 70%;
	bottom: 30%;
	right: 24px;
	width: 200px;
	height: 126px;
	text-align: center;
	border: none;
	outline: none;
	z-index: 2;
}

.center-div {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.banner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
}

.banner-left-line {
	flex: 1;
	height: 1px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin-right: 35px;
}

.banner-right-line {
	flex: 1;
	height: 1px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin-left: 35px;
}

.banner {
	width: 500px;
	height: 70px;
	background-color: var(--product-primary-color);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.banner p {
	color: white;
	font-size: 20px;
	margin: 0;
	padding: 10px;
	text-align: center;
}

.banner::before,
.banner::after {
	content: '';
	position: absolute;
	top: 0;
	width: 0;
	height: 0;
	border-style: solid;
}

.banner::before {
	border-width: 35px;
	border-color: var(--product-primary-color) transparent var(--product-primary-color) transparent;
	left: -35px;
}

.banner::after {
	border-width: 35px;
	border-color: var(--product-primary-color) transparent var(--product-primary-color) transparent;
	right: -35px;
}
</style>
