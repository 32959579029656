<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="{
			'container pb-5': reduced,
			'px-5 pb-5': reduced && !['xs', 'sm'].includes(windowWidth),
			'px-3 pb-3': reduced && ['xs', 'sm'].includes(windowWidth),
		}">
		<div
			class="row no-gutters"
			:style="rowStyle">
			<div
				:class="{
					'd-flex align-items-center': !['xs', 'sm'].includes(windowWidth),
					'justify-content-start': ['xs', 'sm'].includes(windowWidth) && reversed,
					'order-12': ['xs', 'sm'].includes(windowWidth) || reversed,
					'col-lg-6': !reduced,
				}"
				class="col-12 col-md-6">
				<div
					:class="{
						'py-3': ['sm', 'xs'].includes(windowWidth),
						'px-3': ['xs'].includes(windowWidth) && !reduced,
					}"
					:style="['xs', 'sm'].includes(windowWidth) || reduced ? '' : 'padding-left: 145px'">
					<div
						:class="['xs', 'sm'].includes(windowWidth) ? 'justify-content-center' : ''"
						class="row">
						<div :class="['xs', 'sm'].includes(windowWidth) ? 'col' : 'col-auto'">
							<div class="row">
								<div :class="['xs', 'sm'].includes(windowWidth) ? 'col' : 'col-auto'">
									<div
										v-if="!!title"
										class="row mb-4">
										<div class="col">
											<div
												:style="makeHighlightStyle('color')"
												:class="contentClasses"
												class="section-header text-uppercase">
												<span v-html="title" />
											</div>
										</div>
									</div>
									<div
										:class="!!Object.keys(cta).length ? 'mb-4' : ''"
										class="row">
										<div
											class="col">
											<div
												:class="contentClasses"
												v-html="content" />
										</div>
									</div>
									<div
										v-if="!!Object.keys(cta).length"
										:class="['xs', 'sm'].includes(windowWidth) ? 'justify-content-center' : ''"
										class="row">
										<div
											:class="{
												'ml-5': !['xs', 'sm'].includes(windowWidth) && reversed,
												'mr-5': !['xs', 'sm'].includes(windowWidth) && !reversed,
											}"
											class="col">
											<div
												v-if="cta.videoUrl[language]"
												v-b-modal="'about-video'"
												:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
												class="btn btn-lg text-white pointer rounded-0 py-2"
												:style="{
													'background-color': highlightColor,
												}"
												@click="$emit('update:currentVideoUrl', cta.videoUrl[language])">
												<div :class="ctaClasses">
													{{ cta.text }}
												</div>
											</div>
											<a
												v-else
												:href="cta.url"
												:target="cta.target || '_self'"
												:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
												class="btn btn-lg text-white pointer rounded-0 py-2"
												:style="{
													'background-color': highlightColor,
												}">
												<div :class="ctaClasses">
													{{ cta.text }}
												</div>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				:class="reduced ? '' : 'col-lg-6'"
				class="col-12 col-md-6 m-0">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'h-100'"
					class="d-block w-100"
					:style="backgroundStyles" />
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Color from '../mixins/Color';

export default {
	name: 'AgencyImageSection',
	components: {},
	mixins: [Color, WindowSizes],
	props: {
		image: {
			type: String,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		rowStyle: {
			type: String,
			default: '',
		},
		cta: {
			type: Object,
			default: () => ({}),
		},
		reversed: {
			type: Boolean,
			default: false,
		},
		reduced: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		backgroundStyles() {
			const styles = [
				['xs', 'sm'].includes(this.windowWidth) ? 'height: 100vw;' : '',
				`background-image: url('${this.image}');`,
				'background-size: cover;',
				'background-position: center;',
				'background-repeat: none;',
			];

			return styles.join(' ');
		},
		ctaClasses() {
			const classes = [
				'font-weight-bolder h6 my-2',
				['xs', 'sm'].includes(this.windowWidth) ? 'w-100' : 'mx-auto',
				this.language !== 'es' ? ' text-capitalize' : '',
				'px-5',
			];

			return classes.join(' ');
		},
		contentClasses() {
			return {
				'pl-5': !['xs', 'sm'].includes(this.windowWidth) && this.reversed,
				'pr-5': !['xs', 'sm'].includes(this.windowWidth) && !this.reversed,
				'pl-3': !['xs', 'sm'].includes(this.windowWidth) && !this.reduced && !this.reversed,
				'px-3': ['sm'].includes(this.windowWidth) && !this.reduced,
			};
		},
	},
};
</script>
