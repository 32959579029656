<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section
		:style="{
			height: 'max-content !important',
			paddingTop: '0 !important',
		}"
		:background-style="backgroundStyle">
		<div
			class="h-100"
			:class="{
				'd-flex flex-column justify-content-center align-items-center': !['xs', 'sm', 'md'].includes(windowWidth),
			}">
			<h2
				class="product-tagline"
				:style="{ paddingTop: $replicated.replicatedCheckPromo() ? '140px' : '80px' }"
				v-html="tagline" />
			<b-row
				no-gutters
				class="h-100">
				<b-col
					v-if="!['xs', 'sm', 'md'].includes(windowWidth)"
					cols="12"
					lg="5"
					class="my-auto"
					:style="{
						padding: !['xs', 'sm', 'md'].includes(windowWidth) ? '0 60px' : 0,
					}">
					<div v-if="mainProductLoading">
						<is-loading
							:loading="mainProductLoading"
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')" />
					</div>
					<image-carousel
						v-else
						:images="galleryImages"
						:group-to-show="groupToShow"
						:enable-zoom="true"
						modal-id="intro"
						:highlight-color="highlightColor"
						:interval="carouselInterval"
						no-background />
				</b-col>
				<b-col
					cols="12"
					lg="7"
					class="d-flex flex-column justify-content-center">
					<img
						:src="logo"
						:style="{
							alignSelf: ['xs', 'sm', 'md'].includes(windowWidth) ? 'center' : 'unset',
							width: ['xs', 'sm', 'md'].includes(windowWidth) ? '200px' : '250px'
						}"
						style="margin-bottom: 20px;"
						alt="Product Logo">
					<div
						v-if="['xs', 'sm', 'md'].includes(windowWidth)"
						class="mb-3">
						<div v-if="mainProductLoading">
							<is-loading
								:loading="mainProductLoading"
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')" />
						</div>
						<image-carousel
							v-else
							img-class="w-75"
							:images="galleryImages"
							:group-to-show="groupToShow"
							:enable-zoom="true"
							modal-id="intro"
							:highlight-color="highlightColor"
							:interval="carouselInterval"
							no-background />
					</div>
					<div
						v-if="title"
						class="d-inline hero-title mb-4"
						style="line-height: 1;"
						v-html="title" />
					<div>
						<i
							v-if="mainProductLoading"
							class="fa fa-fw fa-spinner fa-pulse" />
						<template v-else>
							<template v-if="hasPromoPrice">
								<s
									class="h5 font-weight-bold text-muted"
									style="opacity: 0.7">
									{{ mainProductPrice }} USD
								</s>
								<br>
								<div class="row no-gutters">
									<div class="col-auto">
										<h2 class="mt-1">
											<span class="product-primary-color">{{ mainProductPromoPrice }}</span> USD
										</h2>
									</div>
									<div
										v-show="agencyDiscountPercentage"
										class="col d-flex align-items-center">
										<h4>
											<span class="ml-2 badge badge-secondary text-white bg-discount">
												{{ (translate('save_percentage', { percentage: agencyDiscountPercentage })).toUpperCase() }}
											</span>
										</h4>
									</div>
								</div>
							</template>
							<template v-else>
								<h2>
									<span class="product-primary-color">{{ mainProductPrice }}</span> USD
								</h2>
							</template>
						</template>
					</div>
					<p
						class="mb-2"
						v-html="description" />
					<b-row>
						<b-col
							class="py-4 d-flex hidden-scroll-x flex-wrap">
							<div
								v-for="(variant, index) in variantImages"
								:key="`variant-${variant.sku}`"
								class="d-flex flex-column justify-content-between align-items-center"
								:style="{
									marginRight: index === variantImages.length - 1 ? '0' : '20px',
									opacity: variant.sku === groupToShow ? 1 : (variant.text ? 0.5 : 0.3),
									cursor: 'pointer',
								}">
								<template v-if="variant.image">
									<img
										:src="variant.image"
										style="width: 60px"
										alt="Product Flavor"
										@click="groupToShow = variant.sku">
									<div
										class="mb-2"
										:style="`border: solid 2px ${highlightColor}; width: 30px; margin: 3px`" />
								</template>
								<template v-else-if="variant.text">
									<div
										class="btn btn-sm btn-outline border-2 btn-lg rounded-1 pointer cta mb-2"
										:class="{
											'w-100': ['xs', 'sm', 'md'].includes(windowWidth),
										}"
										@click="groupToShow = variant.sku">
										{{ variant.text }}
									</div>
								</template>
							</div>
						</b-col>
					</b-row>
					<b-card
						class="border-0"
						bg-variant="yellow">
						<div class="row h-100 no-gutters">
							<div
								:class="['xs','sm'].includes(windowWidth) ? 'col-12' : ''"
								class="col-auto">
								<div
									class="btn border-2 text-white btn-lg rounded-1 pointer cta"
									:class="{
										'w-100': ['xs', 'sm', 'md'].includes(windowWidth),
									}"
									@click="scrollTo('buy-section')">
									{{ translate('buy_now') }}
								</div>
							</div>
							<!-- 30-day money back guarantee -->
							<div
								:class="['xs','sm'].includes(windowWidth) ? 'col-12 text-center' : ''"
								class="col d-flex mb-2 h-100 align-items-center">
								<div
									:class="['xs','sm'].includes(windowWidth) ? '' : 'ml-3'">
									<p
										:class="['xs','sm'].includes(windowWidth) ? 'pt-0 mt-0 mb-3' : 'pt-0 mt-0 pb-0 mb-0'"
										class="h5 bold-text text-muted">
										<i class="fa fa-check-circle-o mr-1" />
										{{ translate("thirty_day_money_back_guarantee") }}
									</p>
								</div>
							</div>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</div>
	</page-section>
</template>

<script>
import ImageCarousel from '@/components/AgencyProductImageCarousel';
import isLoading from '@/components/Loading';
import WindowSizes from '@/mixins/WindowSizes';
import {
	AGENCY_PRODUCTS,
	PRODUCT_DEFAULT_PRICE,
	PRODUCT_OVERRIDE_PRICE,
} from '@/settings/Products';
import { LandingPages, Store } from '@/translations';
import PageSection from './PageSection';
import PublicStore from '@/mixins/PublicStore';
import Scroll from '../mixins/Scroll';
import AgencySite from '@/mixins/AgencySite';

export default {
	name: 'Hero',
	messages: [LandingPages, Store],
	components: {
		isLoading,
		ImageCarousel,
		PageSection,
	},
	mixins: [Scroll, WindowSizes, PublicStore, AgencySite],
	props: {
		tagline: {
			type: String,
			required: true,
		},
		product: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		logo: {
			type: String,
			required: true,
		},
		variantImages: {
			type: Array,
			required: true,
		},
		highlightColor: {
			type: String,
			required: true,
		},
		backgroundStyle: {
			type: String,
			default: '',
		},
		carouselInterval: {
			type: Number,
			default: 5000,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			outOfStockProducts: {},
			selectedSku: '',
		};
	},
	computed: {
		groupToShow: {
			set(sku) {
				this.selectedSku = sku;
			},
			get() {
				if (this.selectedSku) {
					return this.selectedSku;
				}
				return this.variantImages[0]?.sku ?? '';
			},
		},
		productFromUrl() {
			const { data } = AGENCY_PRODUCTS[this.product];
			return this.updateSingleAgencyProductPrice(data);
		},
		mainProductData() {
			try {
				const data = this.productFromUrl;
				return {
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					priceAmount: data.attributes.price_amount,
					promoPrice: this.getProductPrice(data.attributes, 'promo_price'),
					promoPriceAmount: data.attributes.promo_price_amount,
					children: data.attributes.children,
					extraImages: data.attributes.extra_images,
				};
			} catch (error) {
				return [];
			}
		},
		galleryImages() {
			return this.mainProductData?.extraImages ?? {};
		},
		mainProductPrice() {
			try {
				if (Object.keys(this.mainProductData).length > 0) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price) {
						return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price;
					}
					return this.getDisplayPrice(this.mainProductData);
				}
				return this.getDisplayPrice(this.mainProductData);
			} catch (e) {
				return this.getDisplayPrice(this.mainProductData);
			}
		},
		mainProductPromoPrice() {
			try {
				if (Object.keys(this.mainProductData).length > 0) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.promoPrice) {
						return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.promoPrice;
					}
					return this.getPromoDisplayPrice(this.mainProductData);
				}
				return this.getPromoDisplayPrice(this.mainProductData);
			} catch (e) {
				return this.getPromoDisplayPrice(this.mainProductData);
			}
		},
		hasPromoPrice() {
			return this.mainProductPromoPrice !== this.mainProductPrice;
		},
		mainProductLoading() {
			return !this.productFromUrl;
		},
	},
	mounted() {
		// TODO: Temporal comment
		// if (!this.showPublicStore) {
		// 	this.$router.replace({ name: 'Store' }).catch(() => {});
		// 	return;
		// }
		this.getAvailableDiscounts(this.agencyPromoSwitch, this.agencyDiscountCode);
	},
	methods: {
		getProductPrice(product, priceKey = 'price') {
			const { price, sku } = product;

			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]?.[priceKey]) {
				return PRODUCT_DEFAULT_PRICE[sku][priceKey];
			}

			return price;
		},
		getDisplayPrice(product) {
			const { children, priceAmount } = product;
			// Display a range for parent products that have children with different values.
			if (children && Object.keys(children).length > 0 && priceAmount === 0) {
				const prices = Object.values(children)
					.map((variant) => variant.price_amount)
					.sort((a, b) => a - b);
				return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')}` : `$${priceAmount}`;
			}
			return `$${priceAmount}`;
		},
		getPromoDisplayPrice(product) {
			const { children, priceAmount, promoPriceAmount } = product;
			// Display a range for parent products that have children with different values.
			if (children && Object.keys(children).length > 0 && priceAmount === 0) {
				const prices = Object.values(children)
					.map((variant) => variant.promo_price_amount ?? variant.price_amount)
					.sort((a, b) => a - b);
				return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')}` : `$${priceAmount}`;
			}
			return `$${promoPriceAmount ?? priceAmount}`;
		},
	},
};
</script>
<style lang="scss" scoped>
.cta {
	&.btn:not(.btn-outline) {
		&:not(:hover) {
			background-color: var(--product-primary-color);
		}
		&:hover {
			background-color: var(--product-secondary-color) !important;
			border-color: var(--product-secondary-color) !important;
			opacity: 0.95;
		}
	}
	&.btn-outline {
		&:not(:hover) {
			border-color: var(--product-primary-color);
			color: var(--product-primary-color);
		}
		&:hover {
			border-color: var(--product-secondary-color) !important;
			color: var(--product-secondary-color) !important;
		}
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll-x::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll-x {
	-ms-overflow-style: none;	/* IE and Edge */
	scrollbar-width: none;		/* Firefox */
}

.bg-discount {
	background-color: #9FC642 !important;
}
</style>
