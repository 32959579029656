import bran from './Bran';
import uuth from './Uuth';
import zlem from './Zlem';
import plos from './Plos';
import byom from './Byom';
import tuun from './Tuun';

export const PRODUCT_PAGES = {
	bran,
	uuth,
	zlem,
	plos,
	byom,
	tuun,
};

export default {};
