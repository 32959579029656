<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<image-section
			v-for="(section, index) in sections"
			:key="`benefit-${index}`"
			:highlight-color="highlightColor"
			:image="section.image"
			:title="section.title"
			:content="section.content"
			:reversed="section.reversed"
			:cta="section.cta"
			:row-style="['xs', 'sm'].includes(windowWidth) ? 'background: white' : `background: white; height: 600px !important;`" />
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import ImageSection from '../ImageSection';

export default {
	name: 'AgencyLandingPageBenefits',
	components: {
		ImageSection,
	},
	mixins: [WindowSizes],
	props: {
		highlightColor: {
			type: String,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
	computed: {
	},
};
</script>
<style scoped>
</style>
