<template>
	<section
		class="w-100 h-100 text-dark py-5"
		:style="background">
		<div class="container h-100">
			<slot />
		</div>
	</section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'AgencyPageSection',
	mixins: [WindowSizes],
	props: {
		backgroundStyle: {
			type: String,
			default: '',
		},
	},
	computed: {
		background() {
			return this.backgroundStyle ? this.backgroundStyle : 'background-color: #FFFFFF; !important';
		},
	},
};
</script>
