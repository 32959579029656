<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="h-100">
		<section
			:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'py-5' : 'p-5'"
			class="w-100 h-100 text-dark bg-white">
			<div class="row no-gutters justify-content-center mb-4">
				<div class="col-12 col-md-10">
					<header-block
						text-centered>
						<div class="row no-gutters mt-3 mb-4 pb-3">
							<div
								:class="{ 'px-3': ['xs', 'sm'].includes(windowWidth) }"
								class="col-12 d-flex justify-content-center">
								<h2
									class="font-weight-bolder p-0 m-0 text-uppercase"
									:style="`color: ${highlightColor}; font-size: ${['xs', 'sm'].includes(windowWidth) ? '36px' : '48px'}; letter-spacing: 3px;`">
									{{ translate('testimonials_title') }}
								</h2>
							</div>
						</div>
						<div class="row no-gutters mb-4 pb-2">
							<div
								:class="{ 'px-3': ['xs', 'sm'].includes(windowWidth) }"
								class="col-12 d-flex justify-content-center">
								<p
									class="h3 mb-0 pb-0 "
									style="color: #666;">
									{{ translate('testimonials_description') }}
								</p>
							</div>
						</div>
						<div
							v-if="!!videoUrl"
							class="row no-gutters mb-1">
							<div
								:class="{
									'px-3': ['xs', 'sm'].includes(windowWidth),
								}"
								class="col-12 d-flex justify-content-center">
								<button
									v-b-modal="'modal-review-video'"
									:class="{
										'w-100': ['xs', 'sm'].includes(windowWidth),
										'px-2': !['xs', 'sm'].includes(windowWidth),
									}"
									class="btn btn-lg text-white rounded-0"
									:style="makeHighlightStyle('background-color')">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'mx-2'"
										class="font-weight-bolder h6 my-1">
										{{ translate('watch_video') }}
									</div>
								</button>
							</div>
						</div>
					</header-block>
				</div>
			</div>
			<div class="row no-gutters">
				<div class="col-12">
					<div class="row no-gutters">
						<div class="col-12">
							<b-carousel
								id="review-carousel"
								:interval="0"
								:controls="itemGroups.length > 1">
								<b-carousel-slide
									v-for="(group, groupIndex) in itemGroups"
									:key="`review-group-${groupIndex}`">
									<template slot="img">
										<div class="w-100">
											<div class="container">
												<div class="row no-gutters">
													<div
														v-for="(item, itemIndex) in group"
														:key="`review-item-${itemIndex}`"
														class="col d-flex flex-column align-items-center justify-content-start px-3">
														<div class="row mx-4 mb-4">
															<div class="col">
																<img
																	style="width: auto; height: 100px;"
																	:src="item.image">
															</div>
														</div>
														<div class="row mb-3">
															<div class="col d-flex justify-content-center">
																<template v-for="(star, index) in Array(5)">
																	<i
																		v-if="index < item.stars"
																		:key="index"
																		class="fas fa-star mx-2 font-lg"
																		:style="makeHighlightStyle('color')" />
																	<i
																		v-else
																		:key="`${index}-else`"
																		class="fas fa-star mx-2 text-secondary font-lg" />
																</template>
															</div>
														</div>
														<div class="row m-3">
															<div
																class="col text-gray text-center"
																:style="`color: ${textColor}`"
																v-html="item.content" />
														</div>
														<div class="row mx-4 mb-3">
															<div
																class="col text-center"
																:style="makeHighlightStyle('color')">
																<span class="font-weight-bolder author">
																	{{ item.author }}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
								</b-carousel-slide>
							</b-carousel>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="disclaimer.length > 0"
				class="my-5 pt-4">
				<div
					class="mx-auto text-center"
					:style="`color: ${textColor}; max-width: ${['xs', 'sm', 'md'].includes(windowWidth) ? '83.5%' : '83.5%'};`">
					<div
						class="p-4 mx-auto text-medium"
						style="background-color: #f5f5f5">
						<sup>*</sup> <span v-html="disclaimer" />
					</div>
					<div
						class="mt-3"
						v-html="disclaimer2" />
				</div>
			</div>
		</section>
		<b-modal
			id="modal-review-video"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="videoUrl[language]"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import Color from '../mixins/Color';
import HeaderBlock from './HeaderBlock';

export default {
	name: 'AgencyReviews',
	messages: [LandingPages],
	components: { HeaderBlock },
	mixins: [Color, WindowSizes],
	props: {
		reviews: {
			type: Array,
			required: true,
		},
		videoUrl: {
			type: Object,
			default() {
				return {};
			},
		},
		disclaimer: {
			type: String,
			default: '',
		},
		disclaimer2: {
			type: String,
			default: '',
		},
		textColor: {
			type: String,
			required: true,
		},
	},
	computed: {
		itemsPerSlide() {
			const perSlide = {
				xs: 1,
				sm: 2,
				md: 2,
				lg: 3,
				xl: 3,
			};

			return perSlide[this.windowWidth];
		},
		itemGroups() {
			return this.reviews.reduce((accumulator, item) => {
				if (accumulator.length === 0 || accumulator[accumulator.length - 1].length >= this.itemsPerSlide) {
					accumulator.push([]);
				}

				accumulator[accumulator.length - 1].push(item);

				return accumulator;
			}, []);
		},
	},
};
</script>
<style scoped>
#review-carousel >>> .carousel-control-next,
#review-carousel >>> .carousel-control-prev /*, .carousel-indicators */ {
	filter: invert(100%);
}

.author {
	font-size: 1rem;
}
</style>
