<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="stages-section">
		<section
			:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'pt-5' : 'pt-5 px-5'"
			class="w-100 text-dark bg-white">
			<div class="row no-gutters justify-content-center">
				<div class="col-12 col-md-11 col-lg-10">
					<div class="row my-3">
						<div class="col">
							<header-block text-centered>
								<div class="d-flex justify-content-center">
									<div
										style="max-width: 600px !important;"
										v-html="intro" />
								</div>
							</header-block>
						</div>
					</div>
					<div class="row no-gutters">
						<div class="col px-3">
							<div class="row">
								<div
									v-for="(stage, index) in stages"
									:key="index"
									class="col-12 col-sm-6 p-5"
									:style="`background-image: url(${stage.backgroundImage}); ${ index === 0 ? 'background-color: var(--product-primary-color);' : '' }`">
									<div class="row">
										<div class="col-12 col-lg-6 p-3">
											<div class="d-flex align-items-center justify-content-center">
												<img
													class="img-fluid"
													style="max-height: 40vh !important;"
													:src="stage.image">
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<h3 :class="index === 0 ? 'text-white' : ''">
												{{ stage.title }}
											</h3>
											<div
												:class="index === 0 ? 'text-white' : ''"
												v-html="stage.description" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import HeaderBlock from './HeaderBlock';
import Scroll from '../mixins/Scroll';

export default {
	name: 'AgencyStages',
	messages: [LandingPages],
	components: {
		HeaderBlock,
	},
	mixins: [Scroll, WindowSizes],
	props: {
		intro: {
			type: String,
			required: true,
		},
		stages: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.stages-section::v-deep {
	h3 {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
		margin: 0;
		color: var(--product-primary-color);
		line-height: 30px;
		letter-spacing: 1px;
	}
}
</style>
