var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		'container pb-5': _vm.reduced,
		'px-5 pb-5': _vm.reduced && !['xs', 'sm'].includes(_vm.windowWidth),
		'px-3 pb-3': _vm.reduced && ['xs', 'sm'].includes(_vm.windowWidth),
	}},[_c('div',{staticClass:"row no-gutters",style:(_vm.rowStyle)},[_c('div',{staticClass:"col-12 col-md-6",class:{
				'd-flex align-items-center': !['xs', 'sm'].includes(_vm.windowWidth),
				'justify-content-start': ['xs', 'sm'].includes(_vm.windowWidth) && _vm.reversed,
				'order-12': ['xs', 'sm'].includes(_vm.windowWidth) || _vm.reversed,
				'col-lg-6': !_vm.reduced,
			}},[_c('div',{class:{
					'py-3': ['sm', 'xs'].includes(_vm.windowWidth),
					'px-3': ['xs'].includes(_vm.windowWidth) && !_vm.reduced,
				},style:(['xs', 'sm'].includes(_vm.windowWidth) || _vm.reduced ? '' : 'padding-left: 145px')},[_c('div',{staticClass:"row",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'justify-content-center' : ''},[_c('div',{class:['xs', 'sm'].includes(_vm.windowWidth) ? 'col' : 'col-auto'},[_c('div',{staticClass:"row"},[_c('div',{class:['xs', 'sm'].includes(_vm.windowWidth) ? 'col' : 'col-auto'},[(!!_vm.title)?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"section-header text-uppercase",class:_vm.contentClasses,style:(_vm.makeHighlightStyle('color'))},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})])])]):_vm._e(),_c('div',{staticClass:"row",class:!!Object.keys(_vm.cta).length ? 'mb-4' : ''},[_c('div',{staticClass:"col"},[_c('div',{class:_vm.contentClasses,domProps:{"innerHTML":_vm._s(_vm.content)}})])]),(!!Object.keys(_vm.cta).length)?_c('div',{staticClass:"row",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'justify-content-center' : ''},[_c('div',{staticClass:"col",class:{
											'ml-5': !['xs', 'sm'].includes(_vm.windowWidth) && _vm.reversed,
											'mr-5': !['xs', 'sm'].includes(_vm.windowWidth) && !_vm.reversed,
										}},[(_vm.cta.videoUrl[_vm.language])?_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('about-video'),expression:"'about-video'"}],staticClass:"btn btn-lg text-white pointer rounded-0 py-2",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'w-100' : '',style:({
												'background-color': _vm.highlightColor,
											}),on:{"click":function($event){return _vm.$emit('update:currentVideoUrl', _vm.cta.videoUrl[_vm.language])}}},[_c('div',{class:_vm.ctaClasses},[_vm._v(" "+_vm._s(_vm.cta.text)+" ")])]):_c('a',{staticClass:"btn btn-lg text-white pointer rounded-0 py-2",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'w-100' : '',style:({
												'background-color': _vm.highlightColor,
											}),attrs:{"href":_vm.cta.url,"target":_vm.cta.target || '_self'}},[_c('div',{class:_vm.ctaClasses},[_vm._v(" "+_vm._s(_vm.cta.text)+" ")])])])]):_vm._e()])])])])])]),_c('div',{staticClass:"col-12 col-md-6 m-0",class:_vm.reduced ? '' : 'col-lg-6'},[_c('div',{staticClass:"d-block w-100",class:['xs', 'sm'].includes(_vm.windowWidth) ? '' : 'h-100',style:(_vm.backgroundStyles)})])])])}
var staticRenderFns = []

export { render, staticRenderFns }